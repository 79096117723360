﻿import { Injectable } from '@angular/core';
import { MENU } from '../../../src/settings/menu';
import { Subject } from 'rxjs';
import { GenericService } from './generic.service';
import { AjaxService } from './ajax.service';
@Injectable()
export class GlobalService
{
    static spinner;
    static user: any;
    static subscription: any;
    static ajaxSubscription: any = [];
    static projectId: number;
    static title;
    static data:any=[];
    static path: string;
    private static _titleObserver = new Subject();
    static titleObservable = GlobalService._titleObserver.asObservable();
    private static _filtersObserver = new Subject();
    static filtersObservable = GlobalService._filtersObserver.asObservable();
    static ajaxService: AjaxService;  
    private static _filters: any[];
    private static _defaultFilters: any[] =
        [
            //{ Id: "View Type", Title: "View Type", Selected: "", List: [{ Id: 1, Name: "Overall" },{ Id: 2, Name: "Trend" }], Multiple: false, Disabled: true },
            { Id: "Segment", Title: "Segment", Selected: [], List: [] },
            { Id: "Methdology", Title: "Methdology", Selected: [], List: [] },// Selected: '1', Clearable: false, List: [{ 'Id': '1', Title: "Nakheel Malls" }], Change: 'changeMallType', Visible: false },//Multiple: false
            { Id: "Customer Category", Title: "Customer Category", Selected: [], List: [] },
        ];

    static routeChange() {
        this.path = GenericService.getCurrentPath();
        this.showHideFilters();
    }
    static showHideFilters() {
        //for (let f of this.filters)
        //{
        //    f.Visible = true;
        //}
    }
    static dataBind() {

        this._filters.filter(x => x.Id != 'View Type').forEach(x => {
            x.List = [];

        });

        this._filters.forEach(x => {
            let dt = this.data.filter(flt => flt.Group == x.Id);
            for (let n of dt) {
                if (x.Selected.indexOf(n.ID.toString())== -1)
                    x.List.push({ Group: n.Group, Name: n.Name, Id: n.ID, Selected:false });
                else
                    x.List.push({ Group: n.Group, Name: n.Name, Id: n.ID, Selected: true  });
            }
        });



    }
    static set setData(data) {
        this.data = data;
    }
    static get filters() {
        if (!this._filters || this._filters.length == 0)
            this._filters = GenericService.clone(this._defaultFilters);

        this.dataBind();

        return this._filters;
    }

    public static getFilters() {
        return this._filters;
    }

    static set filters(filters) {
        this._filters = filters;
        this.emitFilterChanges();
    }



    static emitFilterChanges(createClone = false) {
        if (createClone)
            this._filters = GenericService.clone(this.filters);
        this._filtersObserver.next(this.filters);
    }
    //static loadFilters(masterService) {
    //    let filterIds = this._defaultFilters.map(x => x.Id);

    //    masterService.callService("variable-label/list?variables=" + filterIds, true)
    //        .subscribe(
    //            (variableLabels) => {
    //                this.path = GenericService.getCurrentPath();
    //                for (let f of this.filters) {

    //                    let vl = variableLabels.find(x => x.Name == f.Id);
    //                    f.List = vl ? Object.keys(vl.ValueLabels).map(key => {
    //                        let obj: any = { Id: vl.ValueLabels[key].Value, Title: vl.ValueLabels[key].Label };
    //                        return obj;
    //                    }) : [];

    //                    f.Multiple = f.Multiple == undefined ? true : f.Multiple;
    //                    if (f.Pages && !f.Pages.find(x => x == this.path.replace("/", "")))
    //                        f.Visible = false;

    //                    f.Visible = f.Visible == undefined ? true : f.Visible;

    //                    f.Clearable = f.Clearable == undefined ? true : f.Clearable;
    //                    f.EnableSearch = false;

    //                    if (f.Change && GlobalService[f.Change] && f.Selected && f.Selected.length > 0)
    //                        GlobalService[f.Change](masterService);
    //                }
    //                let f = this.filters.find(x => x.Id == 'Wave');
    //                //f.Selected = [String(this.getLatestWave())];
    //                f.Selected = [f.List[f.List.length - 1].Id];
    //                if (f.List.length > 1)
    //                    f.Selected.push(f.List[f.List.length - 2].Id);
    //                if (f.List.length > 2)
    //                    f.Selected.push(f.List[f.List.length - 3].Id);
    //                this.emitFilterChanges();
    //            });
    //}


    static changeTitle(newTitle: string)
    {
        let that = this;
        setTimeout(function ()
        {
            that.title = newTitle;
            that._titleObserver.next(newTitle);
        }, 100);
    }

    static getMenuItemByPath(path: string)
    {
        path = path.split('?')[0];

        for (let item of MENU)
        {
            if (item.path == path)
                return item;
            if (item.sub)
            {
                for (let subItem of item.sub)
                {
                    if (subItem.path == path)
                        return subItem;
                }
            }
        }
    }

    static getPageTitleByPath(path: string)
    {
        path = path.split('?')[0];
        let item = this.getMenuItemByPath(path);
        return item.title;
    }

    static unsubscribeAjax()
    {
        for (let i = 0; i < this.ajaxSubscription.length; i++)
        {
            if (this.ajaxSubscription[i])
                this.ajaxSubscription[i].unsubscribe();
        }
        this.ajaxSubscription = [];
    }

    static unsubscribe()
    {
        if (this.subscription)
            this.subscription.unsubscribe();

        this.unsubscribeAjax();
    }
    static getNameCSV(list) {
        if (list && list.length > 0) {
            return list.map(function (k) { return k["Name"] }).join(",");
        }
        else
            return "";
    }
}
