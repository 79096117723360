import { Component } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { ActivatedRoute } from "@angular/router";
import { ChartService } from 'app/providers/chart.service';
import { HttpClient } from '@angular/common/http';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { DATA } from 'data/data';
import { retry } from 'rxjs/operators';
import { forEach } from '@angular/router/src/utils/collection';
import { ModalService } from '../../providers/modal.service';
@Component({
    moduleId: module.id,
    selector: 'promotion',
    templateUrl: './promotion.component.html',
    styleUrls: ['./promotion.component.scss']
})

export class PagePomotionComponent
{
    title: string;
    gs = GlobalService;
    mode = 'category';
    data: any = [];
    filteredItems: any = [];
    tabs: any = ["Salty Snacks", "Nuts", "Popcorn"];
    selectedTabIndex = 0;
    configs: any = [
        { id: 'chart1', class: 'col-md-12', chartOptions: { yAxisMaxValue: 120, canvasPadding: 40, showPercentValues: 0, numberSuffix: '' }, height: '50vh', type: "stackedcolumn2d", title: '', Group: 'Trend' },
        { id: 'chart2', class: 'col-md-12', chartOptions: { yAxisMaxValue: 120, canvasPadding: 40, showPercentValues: 0, numberSuffix: '' }, height: '50vh', type: "doughnut2d", title: '', Group: 'SubOverall' },
        { id: 'chart3', class: 'col-md-12', chartOptions: { yAxisMaxValue: 120, canvasPadding: 40, showPercentValues: 0, numberSuffix: '' }, height: '50vh', type: "doughnut2d", title: '', Group: 'SubOverall' }]

    popupConfigs: any = []
    filteredData = [];
    selectedManufacturer: string = "";
    finalConfig = [];
    modalConfig = [];

    constructor(private route: ActivatedRoute, private chartService: ChartService, private http: HttpClient, private modalService:ModalService)
    {

        this.gs.filters.map(x =>
        {
            if (x.Id == 'View Type')
            {
                x.Selected = "Trend";
            }
        });
        this.data = DATA;
        this.gs.routeChange();
        this.onSequenceChangeEvent(this.selectedTabIndex);
        this.gs.changeTitle('Pomotion');
        GlobalService.filtersObservable.subscribe(filters =>
        {
            this.onSequenceChangeEvent(this.selectedTabIndex);
        });
    }

    async ngOnInit()
    {

        this.data = DATA;  //await this.http.get("assets/data/data.json").toPromise();
        FusionChartsService.clickObservable.subscribe(obj =>
        {
           //
            if (Object.keys(obj).find(x => x == "datasetName"))
                this.selectedManufacturer = obj[Object.keys(obj).find(x => x == "datasetName")];
            else
                this.selectedManufacturer = obj[Object.keys(obj).find(x => x == "categoryLabel")];
           // alert(this.selectedManufacturer);
            //this.onSequenceChangeEvent(this.selectedTabIndex);
           // var viewSelect = this.gs.filters.filter(x => x.Id == 'View Type')[0].Selected;
            this.modalConfig = this.configs.filter(x => x.Group =="SubOverall");
            for (let config of this.modalConfig)
            {
                this.getChart(this.tabs[this.selectedTabIndex], config);
            }
            this.openModal("custom-modal-1");
        });
    }

    ngOnDestroy()
    {
        this.gs.unsubscribe();
    }

    //getAppleProducts()
    //{
    //    this.filteredItems = this.data.filter(x => x.Category == 'Category' && x.Brand == 'Apple');
    //    this.mode = 'items';
    //    this.getChart('iPhone 8 plus 64gb');
    //}
    onSequenceChangeEvent(index)
    {
        var viewSelect = this.gs.filters.filter(x => x.Id == 'View Type')[0].Selected;
        this.finalConfig = this.configs.filter(x => x.Group == viewSelect);
        this.getFilterdData();
        for (let config of this.finalConfig)
        {
            this.getChart(this.tabs[index], config);
        }

    }
    getChart(category, config)
    {
        var chartData = [];
        var table = { Stubs: [], Banners: [] };
        var filters = this.gs.filters;
        chartData = this.filteredData.filter(x => x.Category == category);
        if (config.id == "chart1")
        {
            table = this.chartTableData("Wave", "Promo Category", chartData, category);
        }
        else if (config.id == "chart2")
        {
            table = this.chartTableData("Location", "Wave", chartData, category);
        }
        else if (config.id == "chart3")
        {
            table = this.chartTableData("Promo Type", "Wave", chartData, category);
        }
       
        let nestedArrays = table.Banners.map(x => x.Values.map(y => y.Freq));
        let values = [].concat(...nestedArrays);
        config.chartOptions.yAxisMinValue = Math.min.apply(Math, values) - 50;

        config.table = table;
        if (config.id == "chart1")
            config.title = "Category";
        else if (config.id == "chart2")
            config.title = "Location of " + this.selectedManufacturer + " - " + this.tabs[this.selectedTabIndex] ;
        else if (config.id == "chart3")
            config.title = "Type of " + this.selectedManufacturer + " - " + this.tabs[this.selectedTabIndex] ;

        FusionChartsService.renderChart(config);
    }

    chartTableData(stubName, bannerName, data, category)
    {
        let filter = [];
        filter = this.dataBind(data);
        var table = { Stubs: [], Banners: [] };

        for (let stub of filter.find(x => x.Id == stubName).List)
        {
            table.Stubs.push({ Id: stub, Name: stub, Color: null, Base: null });
        }

        for (let banner of filter.find(x => x.Id == bannerName).List)
        {

            let value = [];
            for (let stub of filter.find(x => x.Id == stubName).List)
            {

                let cd = data.filter(x => x.Category == category && x[stubName] == stub && x[bannerName] == banner);
                if (cd.length == 0)
                {
                    value.push({ Freq: 0, Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "{br} Time: " + this.getRandomArbitrary(1, 12) + ":" + this.getRandomArbitrary(1, 59) + this.getRandomAMPM() });
                }
                else
                {
                    value.push({ Freq: parseInt(cd[0]["Share"]), Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "{br} Time: " + this.getRandomArbitrary(1, 12) + ":" + this.getRandomArbitrary(1, 59) + this.getRandomAMPM() });//, Perc: parseInt(cd[0]["Share"])
                }
            }
            table.Banners.push({ Id: banner, Name: banner, Color: null, Base: null, Values: value });
        }
        return table;
    }
    dataBind(data)
    {
        let values = [{ Id: "Promo Category", Title: "Promo Category", List: [] },
            { Id: "Wave", Title: "Wave", List: [] },
            { Id: "Location", Title: "Location", List: [] },
            { Id: "Promo Type", Title: "Promo Type", List: [] },
        ];
        for (let n of data)
        {
            values.forEach(x =>
            {
                if (x.List.indexOf(n[x.Id]) == -1)
                    x.List.push(n[x.Id]);
            });
        }
        return values;
    }
    getRandomArbitrary(min, max)
    {
        return parseInt(Math.random() * (max - min) + min);
    }

    getRandomAMPM()
    {
        let arr = ['AM', 'PM'];
        var index = this.getRandomArbitrary(0, 2);
        return " " + arr[index];
    }

    getFilterdData()
    {
        var filters = this.gs.filters;
        this.filteredData = this.data;
        for (let flt of filters)
        {
            if (flt.Id == 'View Type')
                continue;
            if (flt.Selected && flt.Selected.length > 0)
            {
                this.filteredData = this.filteredData.filter(x => flt.Selected.indexOf(x[flt.Id]) > -1);
            }
        }
    }

    openModal(id: string)
    {
        this.modalService.open(id);
    }
    closeModal(id: string)
    {
        this.modalService.close(id);
    }
}

