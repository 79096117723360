export const MENU: any = [
    {
        title: 'Dashboard',
        icon: {
            class: 'fa fa-check',
            bg: '#00568A',
            color: '#fff'
        },
        routing: '/home'
    },
    //{
    //    title: 'Promotion',
    //    icon: {
    //        class: 'fa fa-check',
    //        bg: '#00568A',
    //        color: '#fff'
    //    },
    //    routing: '/promotion'
    //}
];

export const View: any = [
    {
        title: 'Overall',
        icon: {
            class: 'fa fa-pie-chart',
            bg: '#00568A',
            color: '#fff'
        },
    },
    {
        title: 'Trend',
        icon: {
            class: 'fa fa-bar-chart',
            bg: '#00568A',
            color: '#fff'
        },
    }
]