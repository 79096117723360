import { Component, OnInit, Input } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { GenericService } from '../../providers/generic.service';
import { GlobalService } from '../../providers/global.service';
import { ChartService } from '../../providers/chart.service';
import { map, catchError } from 'rxjs/operators';

@Component({
  moduleId: module.id,
  selector: 'default-layout',
  templateUrl: 'default.component.html',
  styleUrls: ['../layouts.scss']
})
export class DefaultLayoutComponent
{
  pageTitle: any; 
  pageIcon: any; // NC - 26th-dec-2018 : Show PageIcon steadPagetite in Horizontal Menu
  boxed: boolean;
  compress: boolean;
  menuStyle: string;
  rtl: boolean;
  @Input() openedSidebar: boolean;
  subscription;

  constructor(private router: Router, private http: HttpClient)
  {
    this.boxed = false;
    this.compress = false;
    this.menuStyle = 'style-3';
    this.rtl = false;

    var x = window.matchMedia("(max-width: 991px)");
    if (x.matches)
      this.openedSidebar = false;
    else
      this.openedSidebar = true;

    this.subscription = GlobalService.titleObservable.subscribe(
      title =>
      {
        this.pageTitle = title;
        GlobalService.path = GenericService.getCurrentPath();
      }
    );
    }


  getClasses()
  {
    let menu: string = (this.menuStyle);

    return {
      ['menu-' + menu]: menu,
      'boxed': this.boxed,
      'compress-vertical-navbar': this.compress,
      'open-sidebar': this.openedSidebar,
      'rtl': this.rtl
    };
  }

  sidebarState()
  {
    this.openedSidebar = !this.openedSidebar;
  }

  ngOnDestroy()
  {
    if (this.subscription)
      this.subscription.unsubscribe();
  }
}
