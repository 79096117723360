import { Component } from '@angular/core';
import { GlobalService } from '../../providers/global.service';
import { ActivatedRoute } from "@angular/router";
import { ChartService } from 'app/providers/chart.service';
import { HttpClient } from '@angular/common/http';
import { FusionChartsService } from '../../providers/fusion-charts.service';
import { DATA } from 'data/data';
import { ModalService } from 'app/providers/modal.service';
import { AjaxService } from 'app/providers/ajax.service';
import { ChartComponent } from 'app/custom-components/chart/chart.component';
@Component({
    moduleId: module.id,
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})

export class PageHomeComponent
{
    title: string;
    gs = GlobalService;
    data: any = [];
    filteredItems: any = GlobalService.filters;
    mainConfigs = [];
    statusCountList = [];
    tnps: any;
    ces: any;

    configs: any = [
        {id: 'chart1', class: 'col-md-12', chartOptions: {labelFontSize: 10, valueFontSize: 11, yAxisMaxValue: 140, canvasPadding: 0, showPercentValues: 0, numberSuffix: '%', decimals: '1', exportEnabled: 1,exportFormats: 'PNG=Export as High Quality Image|PDF=Export as Printable|XLSX=Export Chart Data',exportTargetWindow: '_self' }, height: '25vh', type: "mscolumn2d",  Group: 'Overall', service: 'request/getSegment', bannerTitle: '' },
        { id: 'chart2', class: 'col-md-12', chartOptions: { labelFontSize: 10, valueFontSize: 11, yAxisMaxValue: 140, canvasPadding: 0, showPercentValues: 0, numberSuffix: '%', decimals: '1', exportEnabled: 1, exportFormats: 'PNG=Export as High Quality Image|PDF=Export as Printable|XLSX=Export Chart Data', exportTargetWindow: '_self' }, height: '25vh', type: "mscolumn2d", Group: 'Overall', service: 'request/getSegment', bannerTitle: '' },
        { id: 'chart3', class: 'col-md-12', chartOptions: { labelFontSize: 10, valueFontSize: 11, yAxisMaxValue: 140, canvasPadding: 0, showPercentValues: 0, numberSuffix: '%', decimals: '1', exportEnabled: 1, exportFormats: 'PNG=Export as High Quality Image|PDF=Export as Printable|XLSX=Export Chart Data', exportTargetWindow: '_self' }, height: '25vh', type: "mscolumn2d",  Group: 'Overall', service: 'request/getSegment', bannerTitle: '' },
        {
            id: 'chart4', type: 'angulargauge', bannerTitle: '', height: '160', forMode: "overall", indexDrill: '2', title: 'Satisfaction', class: 'col-md-6', service: 'request/getSatisfaction',
            chartOptions: { yAxisMaxValue: 130, canvasPadding: 60, decimals: '1', showTickMarks: 0, showTickValues: 0, majorTMNumber: '0', minorTMNumber: '0', gaugeOuterRadius: 80,
                gaugeInnerRadius: 86, gaugeBorderColor: '{light-0}'},

            colorRange: {
                //color: [{ minValue: 0, maxValue: 10, code: '#9fcb9f' }, { minValue: 10, maxValue: 20, code: '#8bc18b' }, { minValue: 20, maxValue: 30, code: '#77b677' }, { minValue: 30, maxValue: 40, code: '#63ab63' }, { minValue: 40, maxValue: 50, code: '#4fa14f' },
                //    { minValue: 50, maxValue: 60, code: '#3c963c' }, { minValue: 60, maxValue: 70, code: '#288b28' }, { minValue: 70, maxValue: 80, code: '#148114' }, { minValue: 80, maxValue: 90, code: '#007600' }, { minValue: 90, maxValue: 100, code: '#007600' }]
                color: [{ minValue: 0, maxValue: 10, code: '#9dca9d' }, { minValue: 10, maxValue: 20, code: '#92c492' }, { minValue: 20, maxValue: 30, code: '#86bd86' }, { minValue: 30, maxValue: 40, code: '#78b678' }, { minValue: 40, maxValue: 50, code: '#69ae69' },
                    { minValue: 50, maxValue: 60, code: '#58a558' }, { minValue: 60, maxValue: 70, code: '#459b45' }, { minValue: 70, maxValue: 80, code: '#309030' }, { minValue: 80, maxValue: 90, code: '#198419' }, { minValue: 90, maxValue: 100, code: '#007600' }]
            }
        },
        {
            id: 'chart5', type: 'angulargauge', bannerTitle: '', height: '160', forMode: "overall", indexDrill: '2', title: 'Trust', class: 'col-md-6', service: 'request/getTrust',
            chartOptions: {
                yAxisMaxValue: 130, canvasPadding: 60, decimals: '1', showTickMarks: 0, showTickValues: 0, majorTMNumber: '0', minorTMNumber: '0', gaugeOuterRadius: 80,
                gaugeInnerRadius: 86, gaugeBorderColor: '{dark-0},{light-0},{dark-0}'
            },

            colorRange: {
                color: [{ minValue: 0, maxValue: 10, code: '#9dca9d' }, { minValue: 10, maxValue: 20, code: '#92c492' }, { minValue: 20, maxValue: 30, code: '#86bd86' }, { minValue: 30, maxValue: 40, code: '#78b678' }, { minValue: 40, maxValue: 50, code: '#69ae69' },
                    { minValue: 50, maxValue: 60, code: '#58a558' }, { minValue: 60, maxValue: 70, code: '#459b45' }, { minValue: 70, maxValue: 80, code: '#309030' }, { minValue: 80, maxValue: 90, code: '#198419' }, { minValue: 90, maxValue: 100, code: '#007600' }]

                //color: [{ minValue: 0, maxValue: 10, code: '#cce8d5' }, { minValue: 10, maxValue: 20, code: '#bce1c9' }, { minValue: 20, maxValue: 30, code: '#addbbd' }, { minValue: 30, maxValue: 40, code: '#9ed4b0' }, { minValue: 40, maxValue: 50, code: '#8ecda4' },
                //{ minValue: 50, maxValue: 60, code: '#70bf8b' }, { minValue: 60, maxValue: 70, code: '#51b272' }, { minValue: 70, maxValue: 80, code: '#48a468' }, { minValue: 80, maxValue: 90, code: '#3b8654' }, { minValue: 90, maxValue: 100, code: '#34764b' }]
                //color: [{ minValue: 0, maxValue: 10, code: '#a7eda7' }, { minValue: 10, maxValue: 20, code: '#9deb9d' }, { minValue: 20, maxValue: 30, code: '#92e992' }, { minValue: 30, maxValue: 40, code: '#86e686' }, { minValue: 40, maxValue: 50, code: '#78e378' },
                //    { minValue: 50, maxValue: 60, code: '#69e069' }, { minValue: 60, maxValue: 70, code: '#58dd58' }, { minValue: 70, maxValue: 80, code: '#45d945' }, { minValue: 80, maxValue: 90, code: '#19d019' }, { minValue: 90, maxValue: 100, code: '#00cb00' }]
            }
        },

       
        { id: 'chart6', class: 'col-md-12', chartOptions: { valueFontSize: 11, yAxisMaxValue: 8, canvasPadding: 10, showPercentValues: 0, numberSuffix: '', decimals: 1, exportEnabled: 1, exportFormats: 'PNG=Export as High Quality Image|PDF=Export as Printable|XLSX=Export Chart Data', exportTargetWindow: '_self', showLabels: "1", valuePosition: "ABOVE"}, height: '160', type: "msline", title: 'KPI(Average)', Group: 'Overall', service: 'request/getAvg', bannerTitle: '' },
        { id: 'chart7', class: 'col-md-12', chartOptions: { valueFontSize: 11, yAxisMaxValue: 140, canvasPadding: 0, showPercentValues: 0, numberSuffix: '%', decimals: 1, exportEnabled: 1, exportFormats: 'PNG=Export as High Quality Image|PDF=Export as Printable|XLSX=Export Chart Data', exportTargetWindow: '_self' }, height: '25vh', type: "mscolumn2d", title: 'Key Performance Indicators (% of Top2)', Group: 'Overall', service: 'request/getTop2', bannerTitle: '' },
        {
            id: 'chart8', class: 'col-md-12', chartOptions: {
                valueFontSize: 11, yAxisMaxValue: 140, canvasPadding: 0, showPercentValues: 0, numberSuffix: '%', decimals: 1, exportEnabled: 1, exportFormats: 'PNG=Export as High Quality Image|PDF=Export as Printable|XLSX=Export Chart Data', exportTargetWindow: '_self', "showLegend": "0", pieRadius:'50'},  height: '25vh', type: "doughnut2d", title: 'Customer Category', Group: 'Overall', service: 'request/getCategory', bannerTitle: '' },
    ];

    filters: any = [];

    constructor(private ajaxService :AjaxService ,private route: ActivatedRoute, private chartService: ChartService, private http: HttpClient, private modalService: ModalService)
    {
        this.main();
        GlobalService.filtersObservable.subscribe(filters => {
            this.filters = filters;
            this.main();
        });
    }

    ngOnDestroy()
    {
        this.gs.unsubscribe();
    }

    main() {
        let flt = "";
        for (var i = 0; i < this.filters.length; i++) {
            if (this.filters[i].Selected.length > 0) {
                flt += this.filters[i].Title;
                let value = ":(";
                for (var j = 0; j < this.filters[i].Selected.length; j++) {
                    if (this.filters[i].Selected.length == (j + 1))
                        value += this.filters[i].Selected[j];
                    else
                        value += this.filters[i].Selected[j] + ",";
                }
                value += ")";
                flt += value + ';';
            }
        }

        this.ajaxService.callGetService("request/getTNPS?filters=" + flt, true).subscribe(list => {
            if (list.length > 0)
                this.tnps =list[0].Value;
        });
        this.ajaxService.callGetService("request/getCES?filters=" + flt, true).subscribe(list => {
            if (list.length > 0)
                this.ces = list[0].Value;
        });

        for (var i = 0; i < this.configs.length; i++) {
            this.getChart(this.configs[i], flt);
        }
        
       // this.menuController.enable(true);
    }
    getChart(config, flt) {
        var chartData = [];
        let id = [];
        var table = { Stubs: [], Banners: [] };
        var serviceUrl = config.service;

        this.ajaxService.callGetService(serviceUrl + "?filters=" + flt, true).subscribe(list => {
            this.statusCountList = [];
            if (config.id == "chart4" || config.id == "chart5") {
                config.dialValue = list[0].Value;
                FusionChartsService.renderGaugeChart(config);
            }
            else {
                for (var j in list) {
                    if (list[j].Value == null) {
                        list[j].Value = 0;
                    }
                }
                if (config.id == "chart1") {
                    for (var i = 0; i < list.length; i++) {
                        if (Number(list[i].Name.substring(8, 10))<=7) {
                                this.statusCountList.push(list[i]);
                        }
                    }

                }
                else if (config.id == "chart2") {
                    for (var i = 0; i < list.length; i++) {
                        if (Number(list[i].Name.substring(8, 10)) > 7 && Number(list[i].Name.substring(8, 10)) <=14 ) 
                            this.statusCountList.push(list[i]);
                    }
                }
                else if (config.id == "chart3") {
                    for (var i = 0; i < list.length; i++) {
                        if (Number(list[i].Name.substring(8, 10)) > 14) 
                            this.statusCountList.push(list[i]);
                    }
                }
                else {
                    this.statusCountList = list;
                }

                table = this.chartFill(this.statusCountList, config);

                config.table = table;
                if (config.id == 'chart6' || config.id == 'chart7')
                    config.table.Banners[0].Color = "#B4D7A2";
                else
                    config.table.Banners[0].Color = "#92D050";
                FusionChartsService.renderChart(config);
            }
           
        });


    }

    chartFill(data, config) {
        var table2 = { Stubs: [], Banners: [] };
        let value = [];
        for (let stub = 0; stub < data.length; stub++) {
            if (!table2.Stubs.find(t => t.Id == data[stub]["Name"])) {
                let s = { Id: data[stub]["Name"], Name: data[stub]["Name"], Color: null, Base: null };
                table2.Stubs.push(s);
                if (data[stub]["Name"] == "Female")
                    s.Color = "#ECADAE";

                else if (data[stub]["Name"] == "Male")
                    s.Color = "#C03854";

                else if (data[stub]["Name"] == "Trust")
                    s.Color = "#37ABB4";

                else if (data[stub]["Name"] == "Satisfaction")
                    s.Color = "#26787E";

                else if (data[stub]["Name"] == "Western Expact")
                    s.Color = "#96C22C";

                else if (data[stub]["Name"] == "National")
                    s.Color = "#B30838";

                else if (data[stub]["Name"] == "African Expat")
                    s.Color = "#F2BB28";

                else if (data[stub]["Name"] == "Arab Expat")
                    s.Color = "#00B0F0";
                else if (data[stub]["Name"] == "Asian Expat")
                    s.Color = "#AAD486";
            }

            value.push({ Freq: data[stub]["Value"], Perc: data[stub]["Value"], Base: 0, SeriesId: null, Series: null, CategoryId: null, Category: null, Color: null, ToolTip: "Name:" + data[stub]["Name"] + "{br}Value:" + data[stub]["Value"] });
        }

        table2.Banners.push({ Id: config.bannerTitle, Name: config.bannerTitle, Color: null, Base: null, Values: value });
        return table2;
    }

    openModal(id: string)
    {
        this.modalService.open(id);
    }

    closeModal(id: string)
    {
        this.modalService.close(id);
    }
}

