﻿import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericService } from '../../providers/generic.service';
import { environment } from '../../../environments/environment';
import { GlobalService } from 'app/providers/global.service';
import { AjaxService } from 'app/providers/ajax.service';
import { finalize } from 'rxjs/operators';
import { AuthService } from 'app/providers/auth.service';

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})

export class PageLoginComponent
{
    model: any = {};
    loading = false;
    error = '';
    gs = GlobalService;
    filters = [];
    data: any = {};
    constructor(private router: Router, private ajaxService: AjaxService, private authService: AuthService)
    {
    }
   
    login()
    {        
        if (this.model.userName == "admin" && this.model.password == "123") {
            this.ajaxService.callGetService("nameValue/list", true).subscribe(list => {
                this.gs.data = list;
                this.filters = this.gs.filters;
                this.router.navigate(['/home']);
            });
        }
        else {
            this.loading = false;
            this.error = GenericService.fetchErrorMsg("Username and Password does not match.");
            if (!this.error)
                this.error = "Unable to connect to server.";
        }

        //this.loading = true;
        //this.error = null;
        //this.authService.login(this.model.userName, this.model.password)
        //    .subscribe(
        //    (user) =>
        //    {
        //        this.loading = false;
                
        //        //this.router.navigate(['/manager-index']);
        //    },
        //    (err) =>
        //    {
        //        this.loading = false;
        //        this.error = GenericService.fetchErrorMsg(err);
        //        if (!this.error)
        //            this.error = "Unable to connect to server.";
        //    }
        //    );
    }

}
