﻿import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { JwtHelperService  } from '@auth0/angular-jwt';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { GenericService } from './generic.service';
import { environment } from '../../environments/environment';
import { AppConfig } from './app.config';
import { GlobalService } from './global.service';

@Injectable()
export class AjaxService
{
    jwtHelper: JwtHelperService = new JwtHelperService();
    constructor(private http: HttpClient)
    {

    }

    callService1(serviceUrl: string, skipAuth: boolean = false, method = 'Get', data = null): Observable<any>
    {
        let headers;

        //As per documentation of angular-jwt, access_token should have been appended automatically. But not working that why we added it manually
        //Will check it later on. And also need to see how refresh_tokens will work
        if (!skipAuth && !environment["skipAuth"])
        {
            headers = new HttpHeaders(
                {
                    'Content-Type': 'application/json',
                });

            if (GlobalService.user)
                headers = headers.set("Authorization", "Bearer " + GlobalService.user.access_token);
            headers = { headers: headers };
        }

        if (method == 'Get')
        {
            return this.http.get(AppConfig.baseUrl + serviceUrl, headers).pipe(
                map((response) =>
                {
                    return response;
                }),
                catchError(this.handleError)
            );
        }
        else if (method == 'Delete')
        {
            return this.http.delete(AppConfig.baseUrl + serviceUrl, headers).pipe(
                map(response =>
                {
                    return response;
                }), catchError(this.handleError)
            );
        }
        else if (method == 'Put')
        {
            return this.http.put(AppConfig.baseUrl + serviceUrl, data, headers).pipe(
                map(response =>
                {
                    return response;
                }), catchError(this.handleError)
            );
        }
        else
        {
            return this.http.post(AppConfig.baseUrl + serviceUrl, data, headers).pipe(
                map((response) =>
                {
                    return response;
                }),
                catchError(this.handleError)
            );
        }
    }

     callService(serviceUrl: string, skipAuth: boolean = false, method = 'Get', data = null): Observable<any>
    {
        return this.callService1(serviceUrl, skipAuth, method, data);
    }

    callGetService(serviceUrl: string, skipAuth: boolean = false): Observable<any>
    {
        return this.callService(serviceUrl, skipAuth, 'Get');
    }

    callPostService(serviceUrl: string, data: any, skipAuth: boolean = false): Observable<any>
    {
        return this.callService(serviceUrl, skipAuth, 'Post', data);
    }

    callDeleteService(serviceUrl: string): Observable<any>
    {
        return this.callService(serviceUrl, false, 'Delete');
    }

    save(serviceUrl: string, data: any): Observable<any>
    {
        return this.callPostService(serviceUrl + '/save',  data);
    }

    public handleError(error: any)
    {
        var errMsg = GenericService.fetchErrorMessage(error);
        console.log(errMsg);
        return throwError(errMsg);
    }

    public fetchErrorMessage(error: any)
    {
        return GenericService.fetchErrorMessage(error);
    }
}
