import { Injectable } from '@angular/core';
import { map, catchError, debounceTime } from 'rxjs/operators';
import { GenericService } from './generic.service';
import { AjaxService } from './ajax.service';

@Injectable()
export class ChartService
{
  constructor(private ajaxService: AjaxService)
  {

  }

  public getTable(chartConfig)
  {
    var url = chartConfig.service;
    var qs = "";
    if (chartConfig.args)
      qs += "&args=" + chartConfig.args;
    if (chartConfig.filters)
      qs += "&filters=" + chartConfig.filters;

    if (qs.length > 0)
    {
      if (url.indexOf('?') > -1)
        url += qs;
      else
        url += '?' + qs;
    }

    return this.ajaxService.callGetService(url).pipe(debounceTime(500));
  }

  transposeTable(table)
  {
    let newStubs = [];
    let newBanners = [];

    table.Stubs.forEach(stub =>
    {
      newBanners.push({ ID: stub.ID, Name: stub.Name, Color: stub.Color, Base: stub.Base, Values: [] });
    });

    table.Banners.forEach((banner) =>
    {
      let newStub = { ID: banner.ID, Name: banner.Name, Color: banner.Color, Base: banner.Base };
      newStubs.push(newStub);

      for (let j = 0; j < newBanners.length; j++)
      {
        newBanners[j].Values.push(banner.Values[j]);
      }
    });

    table.Stubs = newStubs;
    table.Banners = newBanners;
    return table;
  }

  tableToDataTable(table)
  {
    let dt = [];

    table.Stubs.forEach((stub, i) =>
    {
      let row = { '': stub.Name };
      table.Banners.forEach(banner =>
      {
        row[banner.Name] = banner.Values[i].Perc;
      });
      dt.push(row);
    });

    return dt;
  }

  dataTableToTable(dt)
  {
    let table = { Banners: [], Stubs: [] };

    if (dt.length == 0)
      return table;

    let keys = []; let firstKey;
    for (let row of dt)
    {
      let j = 0;
      for (var key in row)
      {
        if (j == 0)
        {
          firstKey = key;
          j++;
          continue;
        }

        keys.push(key);
      }
    }

    keys = Array.from(new Set(keys));
    keys.forEach(x =>
    {
      table.Banners.push({ Name: x, Values: [] });
    });

    dt.forEach((row, i) =>
    {
      table.Stubs.push({ Name: row[firstKey] });
    });

    table.Banners.forEach((banner, i) =>
    {
      table.Stubs.forEach((stub, j) =>
      {
        let value = 0;
        if (dt[j][banner.Name])
          value = parseFloat(dt[j][banner.Name].toString().replace(",", "")) || 0;
        banner.Values[j] = { Perc: value };
      });
    });

    return table;
  }

  sortBanners(table, bannerOrders)
  {
    table.Banners.sort(function (a, b)
    {
      let x = isNaN(a.Name) ? a.Name : Number(a.Name);
      let y = isNaN(b.Name) ? b.Name : Number(b.Name);

      if (bannerOrders)
        return bannerOrders[x] > bannerOrders[y]
      else
        return x > y;
    });
  }

  sortStubs(table, stubOrders)
  {
    table.Stubs.sort(function (a, b)
    {
      let x = isNaN(a.Name) ? a.Name : Number(a.Name);
      let y = isNaN(b.Name) ? b.Name : Number(b.Name);
      if (stubOrders)
        return stubOrders[x] > stubOrders[y]
      else
        return x > y;
    });
  }

  exportToCsv(data)
  {
    var options =
      {
        fieldSeparator: ',',
        quoteStrings: '"',
        decimalseparator: '.',
        showLabels: true,
        showTitle: false,
        useBom: true,
        headers: Object.keys(data)
      };

    //new Angular2Csv(data, 'csv', options); //this plugin creating problem if we target es5
  }
 
  static getColor(index)
  {
    if (index == undefined || index == null || index < 0 || this.colors.length <= index)
      return GenericService.getRandomColorHex();
    else
      return ChartService.colors[index];
  }

  static colors = [
    '#3B3EAC',
    '#0099C6',
    '#DD4477',
    '#66AA00',
    '#316395',
    '#994499',
    '#22AA99',
    '#6633CC',
    '#E67300',
    '#252d47',
    '#8B0707',
    '#329262',
    '#5574A6',
    '#3B3EAC',
    "#3e95cd",
    "#8e5ea2",
    "#3cba9f",
    "#e8c3b9",
    "#c45850",
  ]
}
