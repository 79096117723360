import { Component } from '@angular/core';
import { AjaxService } from './providers/ajax.service';
import { GlobalService } from './providers/global.service';

@Component({
    moduleId: module.id,
    selector: 'app',
    template: `<router-outlet></router-outlet>`
})
export class AppComponent
{
    gs = GlobalService;
    filters = [];
    constructor(
        private ajaxService: AjaxService
    ) {
        this.ajaxService.callGetService("nameValue/list", true).subscribe(list => {
            this.gs.data = list;
            this.filters = this.gs.filters;
        });
    }
}
