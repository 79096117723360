﻿import { Component, ElementRef } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, Event } from '@angular/router';
import { GlobalService } from '../../../providers/global.service';
import { filter } from 'rxjs/operators';

import { GenericService } from '../../../providers/generic.service';

@Component({
    selector: 'addition-navbar',
    templateUrl: './addition-navbar.component.html',
    styleUrls: ['./addition-navbar.component.scss'],
    host: {
        '[class.addition-navbar]': 'true',
        '[class.open]': 'open',
        '(document:click)': 'documentClick($event)'
    }
})
export class AdditionNavbarComponent
{
    title: string;
    open: boolean;
    path: string;
    subscription: any;
    gs = GlobalService;
    constructor(private _elRef: ElementRef, private router: Router)
    {
        this.title = 'Filters';
        this.open = false;

        this.subscription = this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)).subscribe((event: any) =>
            {
                this.path = event.url.split(";")[0];
                this.gs.filters.forEach((f, index) =>
                {
                    //if (f.Title == 'ad')
                    //{
                    //    //if (path == '/ad-recognition' || path == '/awareness-source')
                    //    //{
                    //    //    //f.SingleSelection = true;
                    //    //    //if (f.List && f.List.length > 0)
                    //    //    //{
                    //    //    //    let selectedFilters = f.List.filter(x => x.Selected);
                    //    //    //    if (selectedFilters.length > 1)
                    //    //    //    {
                    //    //    //        for (let i = 0; i < selectedFilters.length - 1; i++)
                    //    //    //        {
                    //    //    //            selectedFilters[i].Selected = false;
                    //    //    //        }
                    //    //    //    }
                    //    //    //}
                    //    //    f.Visible = true;
                    //    //}
                    //    //else
                    //    //    f.Visible = false;

                    //    //GlobalService.filters[index] = GenericService.clone(f);
                    //}
                });
            });
    }

    toggleNavbar(event = null)
    {
        if (event)
            event.preventDefault();
        this.open = !this.open;
    }

    filterChange(filter)
    {
        //if (filter.Value=='v_week')
        //GlobalService.waveChanged(this.masterservice);
    }

    applyFilters()
    {
        GlobalService.emitFilterChanges();
        this.open = false;
    }

    documentClick(event)
    {
        let clickedByFilterButton = event.target.name == 'filter-button' || (event.target.parentElement && event.target.parentElement.name == 'filter-button');

        if (clickedByFilterButton)
        {
            this.open = !this.open;
        }
        else if (this.open && !this._elRef.nativeElement.contains(event.target))
        {
            this.open = false;
        }
    }

    ngOnDestroy()
    {
        if (this.subscription)
            this.subscription.unsubscribe();
    }
    onClickedOutside(t, event)
    { }
}
