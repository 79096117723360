export const DATA: any = [
    {
        "Brand": "SIMBA",
        "Manufacture": "SIMBA",
        "Wave": "Oct-19",
        "Key Account": "Checkers Hyper Group",
        "Account": "Checkers",
        "Region": "Eastern Cape",
        "Category": "Salty Snacks",
        "Share": "50",
        "Promo Category": "Promo",
        "Location": "Front of Store",
        "Promo Type": "In Aisle Branding Board"
    },
    {
        "Brand": "FRITOS",
        "Manufacture": "KELLOGS",
        "Wave": "Oct-19",
        "Key Account": "Clicks Group",
        "Account": "Checker Hyper",
        "Region": "Northen Cape",
        "Category": "Nuts",
        "Share": "59",
        "Promo Category": "Non Promo",
        "Location": "Bakery",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "CHEAS NAKS",
        "Manufacture": "LAYS",
        "Wave": "Oct-19",
        "Key Account": "Checkers Hyper Group",
        "Account": "Clicks",
        "Region": "Free State",
        "Category": "Nuts",
        "Share": "30",
        "Promo Category": "Non Promo",
        "Location": "Butchery",
        "Promo Type": "In Aisle Shelf Defender"
    },
    {
        "Brand": "ALL OTHER",
        "Manufacture": "DORITOS",
        "Wave": "Oct-19",
        "Key Account": "PNP Group",
        "Account": "OK Foods",
        "Region": "Gauteng",
        "Category": "Nuts",
        "Share": "5",
        "Promo Category": "Non Promo",
        "Location": "Biscuit Isle",
        "Promo Type": "In Aisle Wobbler"
    },
    {
        "Brand": "LAYS",
        "Manufacture": "NIKNAKS",
        "Wave": "Oct-19",
        "Key Account": "Spar Group",
        "Account": "OK Grocer",
        "Region": "Kwazulu Natal",
        "Category": "Popcorn",
        "Share": "13",
        "Promo Category": "Non Promo",
        "Location": "Till point",
        "Promo Type": "Out of Aisle Mega Flex Boards"
    },
    {
        "Brand": "DORITOS",
        "Manufacture": "FRITOS",
        "Wave": "Oct-19",
        "Key Account": "Clicks Group",
        "Account": "PNP",
        "Region": "Limpopo",
        "Category": "Nuts",
        "Share": "4",
        "Promo Category": "Non Promo",
        "Location": "Wine Aisle",
        "Promo Type": "In Aisle Branding Board"
    },
    {
        "Brand": "NIKNAKS",
        "Manufacture": "SIMBA",
        "Wave": "Oct-19",
        "Key Account": "Spar Group",
        "Account": "Shoprite",
        "Region": "Mpumalanga",
        "Category": "Popcorn",
        "Share": "43",
        "Promo Category": "Promo",
        "Location": "Snack Aisle",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "OTHER PEPSICO BRANDS",
        "Manufacture": "KELLOGS",
        "Wave": "Oct-19",
        "Key Account": "Checkers Hyper Group",
        "Account": "PNP Hyper",
        "Region": "North West",
        "Category": "Popcorn",
        "Share": "22",
        "Promo Category": "Promo",
        "Location": "Other",
        "Promo Type": "In Aisle Shelf Defender"
    },
    {
        "Brand": "BIG KORN BITES",
        "Manufacture": "DORITOS",
        "Wave": "Oct-19",
        "Key Account": "SR Chk Group",
        "Account": "PNP Super",
        "Region": "Kwazulu Natal",
        "Category": "Salty Snacks",
        "Share": "22",
        "Promo Category": "Promo",
        "Location": "Front of Store",
        "Promo Type": "In Aisle Wobbler"
    },
    {
        "Brand": "CHEESE CURLS",
        "Manufacture": "NATIONAL BRANDS LIMITED",
        "Wave": "Oct-19",
        "Key Account": "PNP Group",
        "Account": "Shoprite",
        "Region": "Western Cape",
        "Category": "Salty Snacks",
        "Share": "26",
        "Promo Category": "Promo",
        "Location": "Bakery",
        "Promo Type": "Out of Aisle Mega Flex Boards"
    },
    {
        "Brand": "TAITS",
        "Manufacture": "OWN BRANDS",
        "Wave": "Oct-19",
        "Key Account": "Clicks Group",
        "Account": "Spar",
        "Region": "Limpopo",
        "Category": "Nuts",
        "Share": "70",
        "Promo Category": "Promo",
        "Location": "Butchery",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "PRINGLES",
        "Manufacture": "ALL OTHER",
        "Wave": "Oct-19",
        "Key Account": "SR Chk Group",
        "Account": "Superspar",
        "Region": "Mpumalanga",
        "Category": "Salty Snacks",
        "Share": "69",
        "Promo Category": "Promo",
        "Location": "Biscuit Isle",
        "Promo Type": "In Aisle Shelf Defender"
    },
    {
        "Brand": "OTHER WILLARDS BRANDS",
        "Manufacture": "OTHER PEPSICO BRANDS",
        "Wave": "Oct-19",
        "Key Account": "SR Chk Group",
        "Account": "PNP Family",
        "Region": "Eastern Cape",
        "Category": "Salty Snacks",
        "Share": "23",
        "Promo Category": "Promo",
        "Location": "Till point",
        "Promo Type": "In Aisle Wobbler"
    },
    {
        "Brand": "SIMBA",
        "Manufacture": "DORITOS",
        "Wave": "Nov-19",
        "Key Account": "Clicks Group",
        "Account": "PNP",
        "Region": "Kwazulu Natal",
        "Category": "Popcorn",
        "Share": "48",
        "Promo Category": "Promo",
        "Location": "Wine Aisle",
        "Promo Type": "Out of Aisle Mega Flex Boards"
    },
    {
        "Brand": "FRITOS",
        "Manufacture": "NIKNAKS",
        "Wave": "Nov-19",
        "Key Account": "Checkers Hyper Group",
        "Account": "Shoprite",
        "Region": "Limpopo",
        "Category": "Salty Snacks",
        "Share": "34",
        "Promo Category": "Promo",
        "Location": "Snack Aisle",
        "Promo Type": "In Aisle Branding Board"
    },
    {
        "Brand": "CHEAS NAKS",
        "Manufacture": "FRITOS",
        "Wave": "Nov-19",
        "Key Account": "PNP Group",
        "Account": "PNP Hyper",
        "Region": "Mpumalanga",
        "Category": "Salty Snacks",
        "Share": "56",
        "Promo Category": "Promo",
        "Location": "Other",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "ALL OTHER",
        "Manufacture": "SIMBA",
        "Wave": "Nov-19",
        "Key Account": "Spar Group",
        "Account": "PNP Super",
        "Region": "North West",
        "Category": "Nuts",
        "Share": "57",
        "Promo Category": "Promo",
        "Location": "Snack Aisle",
        "Promo Type": "In Aisle Branding Board"
    },
    {
        "Brand": "LAYS",
        "Manufacture": "KELLOGS",
        "Wave": "Nov-19",
        "Key Account": "Clicks Group",
        "Account": "PNP",
        "Region": "Kwazulu Natal",
        "Category": "Salty Snacks",
        "Share": "50",
        "Promo Category": "Non Promo",
        "Location": "Other",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "DORITOS",
        "Manufacture": "SIMBA",
        "Wave": "Nov-19",
        "Key Account": "Spar Group",
        "Account": "Shoprite",
        "Region": "Western Cape",
        "Category": "Salty Snacks",
        "Share": "19",
        "Promo Category": "Non Promo",
        "Location": "Front of Store",
        "Promo Type": "In Aisle Shelf Defender"
    },
    {
        "Brand": "NIKNAKS",
        "Manufacture": "KELLOGS",
        "Wave": "Nov-19",
        "Key Account": "Clicks Group",
        "Account": "PNP Hyper",
        "Region": "Kwazulu Natal",
        "Category": "Popcorn",
        "Share": "35",
        "Promo Category": "Non Promo",
        "Location": "Bakery",
        "Promo Type": "In Aisle Wobbler"
    },
    {
        "Brand": "OTHER PEPSICO BRANDS",
        "Manufacture": "KELLOGS",
        "Wave": "Nov-19",
        "Key Account": "Spar Group",
        "Account": "PNP Super",
        "Region": "Western Cape",
        "Category": "Salty Snacks",
        "Share": "4",
        "Promo Category": "Non Promo",
        "Location": "Butchery",
        "Promo Type": "Out of Aisle Mega Flex Boards"
    },
    {
        "Brand": "BIG KORN BITES",
        "Manufacture": "DORITOS",
        "Wave": "Nov-19",
        "Key Account": "Checkers Hyper Group",
        "Account": "PNP",
        "Region": "Limpopo",
        "Category": "Nuts",
        "Share": "31",
        "Promo Category": "Non Promo",
        "Location": "Biscuit Isle",
        "Promo Type": "In Aisle Branding Board"
    },
    {
        "Brand": "CHEESE CURLS",
        "Manufacture": "NATIONAL BRANDS LIMITED",
        "Wave": "Nov-19",
        "Key Account": "SR Chk Group",
        "Account": "Shoprite",
        "Region": "Mpumalanga",
        "Category": "Salty Snacks",
        "Share": "46",
        "Promo Category": "Non Promo",
        "Location": "Snack Aisle",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "TAITS",
        "Manufacture": "OWN BRANDS",
        "Wave": "Nov-19",
        "Key Account": "PNP Group",
        "Account": "PNP Hyper",
        "Region": "Eastern Cape",
        "Category": "Salty Snacks",
        "Share": "68",
        "Promo Category": "Non Promo",
        "Location": "Other",
        "Promo Type": "In Aisle Shelf Defender"
    },
    {
        "Brand": "PRINGLES",
        "Manufacture": "ALL OTHER",
        "Wave": "Nov-19",
        "Key Account": "Clicks Group",
        "Account": "PNP Super",
        "Region": "North West",
        "Category": "Popcorn",
        "Share": "8",
        "Promo Category": "Non Promo",
        "Location": "Front of Store",
        "Promo Type": "In Aisle Wobbler"
    },
    {
        "Brand": "OTHER WILLARDS BRANDS",
        "Manufacture": "DORITOS",
        "Wave": "Nov-19",
        "Key Account": "SR Chk Group",
        "Account": "Shoprite",
        "Region": "Kwazulu Natal",
        "Category": "Popcorn",
        "Share": "46",
        "Promo Category": "Non Promo",
        "Location": "Bakery",
        "Promo Type": "Out of Aisle Mega Flex Boards"
    },
    {
        "Brand": "SIMBA",
        "Manufacture": "KELLOGS",
        "Wave": "Dec-19",
        "Key Account": "Spar Group",
        "Account": "PNP",
        "Region": "Mpumalanga",
        "Category": "Salty Snacks",
        "Share": "18",
        "Promo Category": "Non Promo",
        "Location": "Butchery",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "FRITOS",
        "Manufacture": "SIMBA",
        "Wave": "Dec-19",
        "Key Account": "Clicks Group",
        "Account": "Shoprite",
        "Region": "Eastern Cape",
        "Category": "Popcorn",
        "Share": "30",
        "Promo Category": "Non Promo",
        "Location": "Biscuit Isle",
        "Promo Type": "In Aisle Shelf Defender"
    },
    {
        "Brand": "CHEAS NAKS",
        "Manufacture": "KELLOGS",
        "Wave": "Dec-19",
        "Key Account": "Spar Group",
        "Account": "PNP Hyper",
        "Region": "Kwazulu Natal",
        "Category": "Salty Snacks",
        "Share": "52",
        "Promo Category": "Promo",
        "Location": "Bakery",
        "Promo Type": "In Aisle Wobbler"
    },
    {
        "Brand": "ALL OTHER",
        "Manufacture": "KELLOGS",
        "Wave": "Dec-19",
        "Key Account": "Checkers Hyper Group",
        "Account": "PNP Super",
        "Region": "Limpopo",
        "Category": "Salty Snacks",
        "Share": "13",
        "Promo Category": "Promo",
        "Location": "Butchery",
        "Promo Type": "Out of Aisle Mega Flex Boards"
    },
    {
        "Brand": "LAYS",
        "Manufacture": "DORITOS",
        "Wave": "Dec-19",
        "Key Account": "SR Chk Group",
        "Account": "PNP",
        "Region": "Mpumalanga",
        "Category": "Nuts",
        "Share": "19",
        "Promo Category": "Promo",
        "Location": "Biscuit Isle",
        "Promo Type": "In Aisle Branding Board"
    },
    {
        "Brand": "DORITOS",
        "Manufacture": "NATIONAL BRANDS LIMITED",
        "Wave": "Dec-19",
        "Key Account": "PNP Group",
        "Account": "Shoprite",
        "Region": "North West",
        "Category": "Salty Snacks",
        "Share": "4",
        "Promo Category": "Promo",
        "Location": "Till point",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "NIKNAKS",
        "Manufacture": "OWN BRANDS",
        "Wave": "Dec-19",
        "Key Account": "Clicks Group",
        "Account": "PNP Hyper",
        "Region": "Kwazulu Natal",
        "Category": "Salty Snacks",
        "Share": "53",
        "Promo Category": "Promo",
        "Location": "Wine Aisle",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "OTHER PEPSICO BRANDS",
        "Manufacture": "ALL OTHER",
        "Wave": "Dec-19",
        "Key Account": "SR Chk Group",
        "Account": "PNP Super",
        "Region": "Western Cape",
        "Category": "Popcorn",
        "Share": "38",
        "Promo Category": "Promo",
        "Location": "Snack Aisle",
        "Promo Type": "In Aisle Shelf Defender"
    },
    {
        "Brand": "BIG KORN BITES",
        "Manufacture": "DORITOS",
        "Wave": "Dec-19",
        "Key Account": "PNP Group",
        "Account": "Shoprite",
        "Region": "Kwazulu Natal",
        "Category": "Salty Snacks",
        "Share": "45",
        "Promo Category": "Promo",
        "Location": "Other",
        "Promo Type": "In Aisle Wobbler"
    },
    {
        "Brand": "CHEESE CURLS",
        "Manufacture": "DORITOS",
        "Wave": "Dec-19",
        "Key Account": "Spar Group",
        "Account": "PNP Super",
        "Region": "Western Cape",
        "Category": "Nuts",
        "Share": "49",
        "Promo Category": "Promo",
        "Location": "Front of Store",
        "Promo Type": "Out of Aisle Mega Flex Boards"
    },
    {
        "Brand": "TAITS",
        "Manufacture": "NATIONAL BRANDS LIMITED",
        "Wave": "Dec-19",
        "Key Account": "Clicks Group",
        "Account": "PNP",
        "Region": "Kwazulu Natal",
        "Category": "Salty Snacks",
        "Share": "37",
        "Promo Category": "Non Promo",
        "Location": "Bakery",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "PRINGLES",
        "Manufacture": "OWN BRANDS",
        "Wave": "Dec-19",
        "Key Account": "Spar Group",
        "Account": "Shoprite",
        "Region": "Western Cape",
        "Category": "Salty Snacks",
        "Share": "47",
        "Promo Category": "Non Promo",
        "Location": "Butchery",
        "Promo Type": "In Aisle Shelf Defender"
    },
    {
        "Brand": "OTHER WILLARDS BRANDS",
        "Manufacture": "ALL OTHER",
        "Wave": "Dec-19",
        "Key Account": "Clicks Group",
        "Account": "PNP Hyper",
        "Region": "Kwazulu Natal",
        "Category": "Popcorn",
        "Share": "67",
        "Promo Category": "Non Promo",
        "Location": "Biscuit Isle",
        "Promo Type": "In Aisle Wobbler"
    },
    {
        "Brand": "SIMBA",
        "Manufacture": "ALL OTHER",
        "Wave": "Jan-20",
        "Key Account": "Spar Group",
        "Account": "PNP Super",
        "Region": "Western Cape",
        "Category": "Popcorn",
        "Share": "41",
        "Promo Category": "Non Promo",
        "Location": "Till point",
        "Promo Type": "Out of Aisle Mega Flex Boards"
    },
    {
        "Brand": "FRITOS",
        "Manufacture": "DORITOS",
        "Wave": "Jan-20",
        "Key Account": "Checkers Hyper Group",
        "Account": "Shoprite",
        "Region": "Limpopo",
        "Category": "Salty Snacks",
        "Share": "11",
        "Promo Category": "Non Promo",
        "Location": "Wine Aisle",
        "Promo Type": "In Aisle Branding Board"
    },
    {
        "Brand": "CHEAS NAKS",
        "Manufacture": "DORITOS",
        "Wave": "Jan-20",
        "Key Account": "SR Chk Group",
        "Account": "PNP",
        "Region": "Mpumalanga",
        "Category": "Salty Snacks",
        "Share": "30",
        "Promo Category": "Non Promo",
        "Location": "Snack Aisle",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "ALL OTHER",
        "Manufacture": "NATIONAL BRANDS LIMITED",
        "Wave": "Jan-20",
        "Key Account": "PNP Group",
        "Account": "Shoprite",
        "Region": "Eastern Cape",
        "Category": "Popcorn",
        "Share": "5",
        "Promo Category": "Non Promo",
        "Location": "Other",
        "Promo Type": "In Aisle Shelf Defender"
    },
    {
        "Brand": "LAYS",
        "Manufacture": "OWN BRANDS",
        "Wave": "Jan-20",
        "Key Account": "Spar Group",
        "Account": "PNP Hyper",
        "Region": "North West",
        "Category": "Popcorn",
        "Share": "14",
        "Promo Category": "Non Promo",
        "Location": "Snack Aisle",
        "Promo Type": "In Aisle Wobbler"
    },
    {
        "Brand": "DORITOS",
        "Manufacture": "ALL OTHER",
        "Wave": "Jan-20",
        "Key Account": "Clicks Group",
        "Account": "PNP Super",
        "Region": "Kwazulu Natal",
        "Category": "Salty Snacks",
        "Share": "34",
        "Promo Category": "Non Promo",
        "Location": "Other",
        "Promo Type": "Out of Aisle Mega Flex Boards"
    },
    {
        "Brand": "NIKNAKS",
        "Manufacture": "CHEAS NAKS",
        "Wave": "Jan-20",
        "Key Account": "Spar Group",
        "Account": "PNP Super",
        "Region": "Mpumalanga",
        "Category": "Popcorn",
        "Share": "23",
        "Promo Category": "Non Promo",
        "Location": "Front of Store",
        "Promo Type": "In Aisle Category Banner"
    },
    {
        "Brand": "OTHER PEPSICO BRANDS",
        "Manufacture": "ALL OTHER",
        "Wave": "Jan-20",
        "Key Account": "Clicks Group",
        "Account": "PNP",
        "Region": "Eastern Cape",
        "Category": "Salty Snacks",
        "Share": "18",
        "Promo Category": "Non Promo",
        "Location": "Bakery",
        "Promo Type": "In Aisle Shelf Defender"
    },
    {
        "Brand": "BIG KORN BITES",
        "Manufacture": "LAYS",
        "Wave": "Jan-20",
        "Key Account": "SR Chk Group",
        "Account": "Shoprite",
        "Region": "Kwazulu Natal",
        "Category": "Salty Snacks",
        "Share": "8",
        "Promo Category": "Non Promo",
        "Location": "Butchery",
        "Promo Type": "In Aisle Wobbler"
    },
    {
        "Brand": "CHEESE CURLS",
        "Manufacture": "DORITOS",
        "Wave": "Jan-20",
        "Key Account": "PNP Group",
        "Account": "PNP Hyper",
        "Region": "Limpopo",
        "Category": "Nuts",
        "Share": "61",
        "Promo Category": "Non Promo",
        "Location": "Biscuit Isle",
        "Promo Type": "Out of Aisle Mega Flex Boards"
    },
    {
        "Brand": "TAITS",
        "Manufacture": "NIKNAKS",
        "Wave": "Jan-20",
        "Key Account": "Spar Group",
        "Account": "PNP Super",
        "Region": "Mpumalanga",
        "Category": "Salty Snacks",
        "Share": "43",
        "Promo Category": "Non Promo",
        "Location": "Snack Aisle",
        "Promo Type": "In Aisle Branding Board"
    },
    {
        "Brand": "PRINGLES",
        "Manufacture": "OTHER PEPSICO BRANDS",
        "Wave": "Jan-20",
        "Key Account": "Clicks Group",
        "Account": "Shoprite",
        "Region": "Limpopo",
        "Category": "Salty Snacks",
        "Share": "67",
        "Promo Category": "Non Promo",
        "Location": "Other",
        "Promo Type": "In Aisle Shelf Defender"
    },
    {
        "Brand": "OTHER WILLARDS BRANDS",
        "Manufacture": "BIG KORN BITES",
        "Wave": "Jan-20",
        "Key Account": "Spar Group",
        "Account": "PNP Super",
        "Region": "Mpumalanga",
        "Category": "Popcorn",
        "Share": "66",
        "Promo Category": "Non Promo",
        "Location": "Front of Store",
        "Promo Type": "In Aisle Wobbler"
    }
]
