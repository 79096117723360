import { BrowserModule }                    from '@angular/platform-browser';
import { RouterModule }                     from '@angular/router';
import { NgModule }                         from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule }          from '@angular/platform-browser/animations';

import { routes, AppRoutingModule }         from './app-routing.module';
import { AppComponent }                     from './app.component';
import { UIModule }                         from './ui/ui.module';
import { NiComponentsModule }               from './ni-components/ni-components.module';
import { PagesModule }                      from './pages/pages.module';

import { DefaultLayoutComponent }           from './layouts/default/default.component';
import { ExtraLayoutComponent }             from './layouts/extra/extra.component';

import { HttpClient, HttpClientModule } from '@angular/common/http';

import { GenericService } from './providers/generic.service';
import { StorageService } from './providers/storage.service';
import { ChartService } from './providers/chart.service';
import { FusionChartsService } from './providers/fusion-charts.service';
import { GlobalService } from './providers/global.service';
import { AjaxService } from './providers/ajax.service';
import { AuthService } from './providers/auth.service';
import { MasterService } from './providers/master.service';

@NgModule({
    declarations: [
        AppComponent,
        DefaultLayoutComponent,
        ExtraLayoutComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes, { useHash: true }),
        HttpClientModule,
        AppRoutingModule,
        UIModule,
        NiComponentsModule,
        PagesModule
    ],
    providers: [
        StorageService,
        GenericService,
        ChartService,
        FusionChartsService,
        GlobalService,
        AjaxService,
        AuthService,
        MasterService
    ],
    bootstrap: [AppComponent]
})

export class AppModule { }
