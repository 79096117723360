import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { Router, ActivatedRoute, Route } from '@angular/router';

@Component({
  moduleId: module.id,
  selector: 'logo',
  templateUrl: 'logo.component.html',
  styleUrls: ['logo.component.scss'],
  host: { 'class': 'app-logo' }
})
export class LogoComponent implements OnInit
{
  baseUrl;
  constructor(private router: Router)
  {
    this.baseUrl = environment.baseUrl;
  }

  ngOnInit() { }

  clickHome()
  {     
      this.router.navigate(['/home']);
  }
}
