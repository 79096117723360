﻿import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DefaultLayoutComponent } from './layouts/default/default.component';
import { ExtraLayoutComponent } from './layouts/extra/extra.component';
import { PageHomeComponent } from './pages/home/home.component';
import { PagePomotionComponent } from './pages/promotion/promotion.component';
import { PageLoginComponent } from './pages/login/login.component';


const defaultRoutes: Routes =
    [
        { path: 'home', component: PageHomeComponent },
        { path: 'promotion', component: PagePomotionComponent }
        
  ];

const extraRoutes: Routes = [
    { path: 'login', component: PageLoginComponent }

];

export const routes: Routes =
    [
        {
            path: '',
            redirectTo: '/login',
            pathMatch: 'full'
        },
        {
            path: '',
            component: DefaultLayoutComponent,
            children: defaultRoutes
        },
        {
            path: '',
            component: ExtraLayoutComponent,
            children: extraRoutes
        },
        {
            path: '**',
            component: DefaultLayoutComponent,
            children: defaultRoutes
        }
    ];

@NgModule({
    imports: [],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }
